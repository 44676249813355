






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import TkStore from '../store/TkStore';
import FocusedVideoTile from './FocusedVideoTile.vue';
import VideoCarousel from './VideoCarousel.vue';
import VideoTile from './VideoTile.vue';
import WatchlistSymbols from './watchlist/WatchlistSymbols.vue';

@Component({
  components: { FocusedVideoTile, VideoCarousel, WatchlistSymbols, VideoTile }
})
export default class SidebarVideoPlaylist extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) playlistElements!: ViluaVideoDocument[];
  /*
   * focused video will extend the sidebar at the first position with a hint for the user
   * with preview image
   */
  @Prop({ type: Object, required: false }) focusedVideo!: ViluaVideoDocument;

  get playlistSlug() {
    return this.$route.params?.playlistSlug || 'rauchfrei';
  }

  get model() {
    return 'playlists';
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || '';
  }

  get sidebarPlaylist() {
    return this.playlistElements.filter(el => {
      if (el._leancms) return el;
      console.error('video inside playlist fails', el);
      return false;
    });
  }

  getPoster(element: ViluaVideoDocument) {
    return this.store.sdk.getThumbnailByVideoDocument(element);
  }

  getVideo(element: ViluaVideoDocument) {
    return element.video?.variations?._default;
  }

  link(element: ViluaVideoDocument) {
    const currentRoute = this.$route.path.split('/');
    return `${currentRoute.slice(0, currentRoute.length - 1).join('/')}/${element._leancms.slug}`;
  }
}
