












































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AllVideos from '../components/AllVideos.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import PodcastList from '../components/podcast/PodcastList.vue';
import Playlist from '../helpers/interface/playlist.interface';
import TkStore from '../store/TkStore';
import UserStore, { WatchlistItem } from '../store/UserStore';

@Component({
  components: { PodcastList, AllVideos, Breadcrumb }
})
export default class WatchListView extends Vue {
  public store: UserStore = getModule(UserStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);

  readonly VIDEOS = 'Videos';
  readonly PODCASTS = 'Podcasts';
  tabs = [this.VIDEOS, this.PODCASTS];
  currentTab = 0;

  get audioWatchlist(): WatchlistItem[] {
    return this.store.audioPlaylist
      .map(item => {
        return {
          ...item,
          // @ts-ignore
          audio: (
            this.tkStore.audioPlaylists.find(
              playlist => playlist._leancms.slug === item.playlistSlug && !!playlist.audios
            ) ||
            // @ts-ignore
            ({ audios: [] } as Playlist)
          ).audios.find(video => video._leancms.slug === item.fileSlug),
          title: item.audio?.title || ''
        };
      })
      .filter(item => !!item.audio);
  }

  get videoWatchlist() {
    return this.store.videoPlaylist
      .map(item => {
        return {
          ...item,
          // @ts-ignore
          video: (
            this.tkStore.videoPlaylists.find(
              playlist => playlist?._leancms.slug === item.playlistSlug && !!playlist.videos
            ) ||
            // @ts-ignore
            ({ videos: [] } as Playlist)
          ).videos.find(video => video._leancms.slug === item.fileSlug),
          title: item.video?.title || ''
        };
      })
      .filter(item => !!item.video);
  }
}
