import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import ViluaAccessTokenResponse from '../helpers/interface/vilua-access-token-response';
import store from '../store/index';
import TkStore from '../store/TkStore';
import UserStore from '../store/UserStore';
import HomeView from '../views/HomeView.vue';
import PageNotFoundView from '../views/PageNotFoundView.vue';
import PlaylistView from '../views/PlaylistView.vue';
import PodcastView from '../views/PodcastView.vue';
import SearchView from '../views/SearchView.vue';
import ThemeView from '../views/ThemeView.vue';
import Video from '../views/VideoView.vue';
import WatchListView from '../views/WatchListView.vue';

const userStore: UserStore = getModule(UserStore, store);
const tkStore: TkStore = getModule(TkStore, store);
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/inhalt-nicht-gefunden',
    name: 'PageNotFound',
    component: PageNotFoundView,
    meta: { restricted: false }
  },
  {
    path: '/zurueck-zum-login',
    name: 'backToLogin',
    component: PageNotFoundView,
    meta: { restricted: false }
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { restricted: false }
  },
  {
    path: '/suche',
    name: 'Search',
    component: SearchView,
    meta: { restricted: true }
  },
  {
    path: '/merkliste',
    name: 'Merkliste',
    component: WatchListView,
    meta: { restricted: true }
  },
  {
    path: '/:themeSlug',
    name: 'Theme',
    component: ThemeView,
    meta: { restricted: true }
  },
  {
    path: '/:themeSlug/:playlistSlug',
    name: 'Playlist',
    component: PlaylistView,
    meta: { restricted: true }
  },
  {
    path: '/:themeSlug/podcast/:podcastSlug',
    name: 'Podcasts',
    component: PodcastView,
    meta: { restricted: true }
  },
  {
    path: '/:themeSlug/:playlistSlug/:videoSlug',
    name: 'Video',
    component: Video,
    meta: { restricted: true }
  },
  {
    path: '*',
    name: 'FallBack',
    component: PageNotFoundView,
    meta: { restricted: false }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

function checkAccessToken() {
  const expiresTimestamp = userStore.expiresTimestamp ?? 0;
  return !!userStore.accessToken && !!userStore.refreshToken && !!(expiresTimestamp - new Date().getTime());
}

async function login(code: string) {
  const response: ViluaAccessTokenResponse = await fetch(`${tkStore.sdk.apiVilua}/oauth/token?code=${code}`, {
    method: 'POST',
    redirect: 'follow'
  }).then(response => response.json());
  if (response?.accessToken) {
    store.commit('setUserTokens', {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      expiresTimestamp: new Date().getTime() + response.expiresIn * 1000
    });
  }
}

function getThemeConfig(to: Route) {
  let themeConfig = undefined;
  // @ts-ignore (need why record.params not in interface)
  if (to.params?.themeSlug) {
    const themeSlug = to.params.themeSlug;
    themeConfig = tkStore.themeConfig.find(theme => theme.theme.toLocaleLowerCase() === themeSlug.toLocaleLowerCase());
  }

  return themeConfig;
}

router.beforeEach(async (to, from, next) => {
  if (to.query?.code) {
    await login(to.query.code as string);
    next(to.path);
  }
  const themeConfig = getThemeConfig(to);
  if (!!themeConfig && to.meta?.restricted) {
    if (checkAccessToken()) {
      next();
    } else {
      window.location.href = themeConfig.url;
    }
  }
  next(); // make sure to always call next()!
});

export default router;
