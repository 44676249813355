














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ViluaRecipeDocument from '../helpers/interface/vilua-recipe-document.interface';
import ViluaRecipeResponse from '../helpers/interface/ViluaRecipeResponse.interface';
import TkStore from '../store/TkStore';
import EnergySquare from './EnergySquare.vue';
import RecipeDescriptionIngredients from './RecipeDescriptionIngredients.vue';
import RecipeDescriptionNutrients from './RecipeDescriptionNutrients.vue';
import SvgBurning from './svg/SvgBurning.vue';
import SvgPrint from './svg/SvgPrint.vue';
import SvgStopWatch from './svg/SvgStopWatch.vue';
import VideoTile from './VideoTile.vue';
import SvgArrowSmall from './svg/SvgArrowSmall.vue';

@Component({
  components: {
    RecipeDescriptionNutrients,
    EnergySquare,
    RecipeDescriptionIngredients,
    SvgPrint,
    SvgStopWatch,
    SvgBurning,
    VideoTile,
    SvgArrowSmall
  }
})
export default class RecipeDescription extends Vue {
  private store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) readonly recipeDocument!: ViluaRecipeDocument;
  private recipe: ViluaRecipeResponse | null = null;
  private collapsed = true;

  mounted() {
    this.getRecipeData();
  }

  async getRecipeData() {
    this.recipe = await this.store.sdk.getRecipeData(this.recipeDocument.recipeId);
  }

  recipePrint() {
    window.print();
  }
}
