
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgStopWatch extends Vue {
  @Prop({ default: () => '28px' }) height!: string;
  @Prop({ default: () => '28px' }) width!: string;
}
