import Vue from 'vue';
import App from './App.vue';
import { EnvResponse } from './helpers/interface/env-response.interface';
import TkSdk from './helpers/tkSdk';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

fetch('/env.json').then(async response => {
  const env: EnvResponse = await response.json();
  const sdk = new TkSdk(env);
  store.commit('addSdk', sdk);
  const data = await fetch(env.vilua_theme_config).then(value => value.json());
  const themeConfig = Object.keys(data).map(key => ({ theme: key, url: data[key] }));
  store.commit('addThemeConfig', themeConfig);

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
