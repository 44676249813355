























































































import { sortBy } from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AutoplayBtn from '../components/AutoplayBtn.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import FocusedVideoTile from '../components/FocusedVideoTile.vue';
import RecipeDescription from '../components/RecipeDescription.vue';
import RelatedContent from '../components/RelatedContent.vue';
import SidebarVideoPlaylist from '../components/SidebarVideoPlaylist.vue';
import Sort from '../components/Sort.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import WatchlistSymbols from '../components/watchlist/WatchlistSymbols.vue';
import Playlist from '../helpers/interface/playlist.interface';
import ViluaDocument from '../helpers/interface/vilua-document.interface';
import ViluaRecipeDocument from '../helpers/interface/vilua-recipe-document.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import SlugValidator from '../mixins/SlugValidator';
import TkStore from '../store/TkStore';
import VideoPlayerStore from '../store/VideoPlayerStore';
import TkVideoThumbnail from '../components/TkVideoThumbnail.vue';
import TkLogo from '../components/TkLogo.vue';
import { ErrorFileFragment } from '../helpers/interface/fragments.interface';

@Component({
  components: {
    RecipeDescription,
    TkVideoThumbnail,
    FocusedVideoTile,
    AutoplayBtn,
    WatchlistSymbols,
    RelatedContent,
    Sort,
    SidebarVideoPlaylist,
    VideoPlayer,
    Breadcrumb,
    TkLogo
  }
})
export default class VideoView extends SlugValidator {
  private store: TkStore = getModule(TkStore, this.$store);
  private videoPlayerStore: VideoPlayerStore = getModule(VideoPlayerStore, this.$store);

  private videoIndexKey = 0;

  sortSelect = 'standard';
  sortItems = [
    { label: 'Standard', value: 'standard', svgComponent: '' },
    { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
    { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
  ];

  created() {
    window.addEventListener('scroll', this.checkFloatModeTrigger);
  }

  mounted() {
    if (!this.isInitialized) {
      this.initializeVideoPlayer();
    }
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkFloatModeTrigger);

    /**
     * if we change the location while video is running
     * in non float-mode, then we want to show the media float player
     */
    if (this.video && this.videoIsPlaying && !this.floatMode) {
      this.videoPlayerStore.enableFloatMode();
    }
  }

  get isInitialized() {
    // @todo: for bug ticket 5580 watch here
    if (this.videoPlayerStore.floatMode) {
      return true;
    }
    return !!this.$route.query['autoplay'];
  }

  get videoSlug() {
    return this.$route.params?.videoSlug || '';
  }

  get floatMode() {
    return this.videoPlayerStore.floatMode;
  }

  get triggeredManually(): boolean {
    return this.videoPlayerStore.triggeredManually;
  }

  get focusedVideo() {
    return this.sortedElements?.find(el => el?._leancms?.slug === this.videoSlug);
  }

  get sortedAndFilteredPlaylist() {
    return this.sortedElements?.filter(el => el?._leancms?.slug !== this.videoSlug);
  }

  get videoPlaylist(): Playlist | undefined {
    return [...this.store.videoPlaylists, ...this.store.recipePlaylist].find(
      playlist => playlist._leancms.slug === this.$route.params?.playlistSlug
    );
  }

  get linkToAll() {
    const currentRoute = this.$route.path.split('/');
    return currentRoute.slice(0, currentRoute.length - 1).join('/');
  }

  get mediaModel() {
    return this.videoPlayerStore.mediaModel;
  }

  get video() {
    const videoList: (ViluaDocument | ErrorFileFragment)[] =
      this.videoPlaylist?.videos ?? this.videoPlaylist?.recipes ?? [];

    if (this.videoPlaylist?.recipes) {
      return videoList.find(recipe => (recipe as ViluaDocument)._leancms.slug === this.videoSlug);
    }
    const video = videoList
      .filter(video => !(video as ErrorFileFragment)?.error)
      .find(video => (video as ViluaDocument)._leancms.slug === this.videoSlug);
    return video;
  }

  get floatTriggerHeight(): number {
    if (this.$refs.videoPlayer) {
      const player: HTMLElement = this.$refs.videoPlayer as HTMLElement;

      const playerHalfHeight = player.clientHeight / 2;
      const playerPosTop = player.getBoundingClientRect().y;
      return playerPosTop + playerHalfHeight;
    } else {
      return 0;
    }
  }

  get videoList() {
    return this.videoPlayerStore.sortedElements;
  }

  get sortedElements() {
    const unsortElements: (ViluaVideoDocument | ViluaRecipeDocument)[] =
      this.videoPlaylist?.videos ?? (this.videoPlaylist?.recipes || []);

    let sortElements = unsortElements;
    switch (this.sortSelect) {
      case 'standard':
        return unsortElements;
      case 'title-asc':
        sortElements = sortBy(unsortElements, (el: ViluaVideoDocument | ViluaRecipeDocument) => {
          return el.title;
        });
        break;
      case 'createdDate-desc':
        sortElements = sortBy(unsortElements, (el: ViluaVideoDocument | ViluaRecipeDocument) => {
          return el?._leancms?.createdAt;
        });
        sortElements = sortElements.reverse();
        break;
    }
    return sortElements;
  }

  get checkIsCurrentVideoSameAsSlug() {
    return this.videoSlug === this.videoPlayerStore.currentVideo?._leancms.slug;
  }

  get recipePlaylist() {
    return this.store.recipePlaylist?.find(
      recipePlaylist => recipePlaylist._leancms.slug === this.$route.params?.playlistSlug
    );
  }

  initializeVideoPlayer(): void {
    if (this.videoPlaylist) {
      this.videoPlayerStore.setMediaModel('video');
    }

    this.videoPlayerStore.setSortedElements(this.sortedAndFilteredPlaylist || []);
    this.videoPlayerStore.setPlaylistRootPath(this.playlistRootPath);
    this.videoPlayerStore.setPlaylist(this.playlistSlug);
    this.videoPlayerStore.setTheme(this.themeSlug);
    this.setVideo(this.videoSlug);
  }

  async startVideo() {
    this.videoPlayerStore.setFloatMode(false);
    this.videoPlayerStore.changeCurrentTime(0);
    this.initializeVideoPlayer();
    this.videoPlayerStore.setVideoPlaying(true);
  }

  nextVideo() {
    this.videoIndexKey++;
    const nextPath = this.videoPlayerStore.currentVideo?._leancms?.slug;
    this.$router.push({ path: nextPath, query: { autoplay: '1' } });
  }

  checkFloatModeTrigger() {
    if (window.scrollY > this.floatTriggerHeight) {
      /**
       * this toggle we need to avoid to disable float-mode
       * after trigger it manually and scrolling down
       */
      this.videoPlayerStore.setTriggeredManually(false);

      if (this.video && this.videoIsPlaying) {
        this.videoPlayerStore.enableFloatMode();
      }
    }

    if (window.scrollY < this.floatTriggerHeight && !this.triggeredManually) {
      if (this.video) {
        this.videoPlayerStore.disableFloatMode();
      }
    }
  }

  get videoIsPlaying(): boolean {
    return this.videoPlayerStore.isPlaying;
  }

  get playlistRootPath() {
    const urlPieces = this.$route.path.split('/');
    urlPieces.splice(urlPieces.length - 1);
    return urlPieces.join('/');
  }

  async setVideo(videoSlug: string) {
    if (this.$route.params?.playlistSlug) {
      const video = await this.store.getVideo({
        playlistSlug: this.$route.params?.playlistSlug,
        videoSlug: videoSlug
      });

      if (video) {
        this.videoPlayerStore.setCurrentVideo(video);
      }
    }
  }

  get playlistSlug() {
    return this.$route.params?.playlistSlug || '';
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || '';
  }

  @Watch('videoSlug', { immediate: true })
  async changeRouteParams(videoSlug: string) {
    /*
     * if we select a video from the sidebar-videoPlaylist
     * we have to start the video from the begin
     */
    if (!this.$route.query['disabled-float-mode']) {
      this.videoPlayerStore.changeDuration(0);
      this.videoPlayerStore.changeCurrentTime(0);
    }

    // @todo: for bug ticket 5580 watch here
    if (!this.videoPlayerStore.floatMode) {
      await this.setVideo(videoSlug);
    }

    await this.$nextTick();
    this.videoIndexKey++;
  }
}
