











import { Component, Watch } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ErrorFileFragment, ImageFileFragment } from '../helpers/interface/fragments.interface';
import TkStore from '../store/TkStore';
@Component
export default class VideoView extends Vue {
  private tkStore: TkStore = getModule(TkStore, this.$store);

  private thumbnail = '';

  get playlistSlug() {
    return this.$route.params?.playlistSlug || '';
  }

  get videoSlug() {
    return this.$route.params?.videoSlug || '';
  }

  async thumbnailPath() {
    const video = await this.tkStore.getVideo({ playlistSlug: this.playlistSlug, videoSlug: this.videoSlug });
    if (video) {
      const defaultImage = video.poster?._default;
      if (!defaultImage || (defaultImage as ErrorFileFragment).error) {
        console.error('poster image path', video.poster);
        return '';
      }
      const basicPath = (defaultImage as ImageFileFragment).path;
      return this.tkStore.sdk.getFilePath(basicPath);
    }
    return '';
  }

  @Watch('videoSlug', { immediate: true })
  async handler() {
    this.thumbnail = await this.thumbnailPath();
  }
}
